/* Copyright 2014 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

 .react-pdf__Page__textContent {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1.0;
}

.react-pdf__Page__textContent > div {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  transform-origin: 0% 0%;
}

.react-pdf__Page__textContent .highlight {
  margin: -1px;
  padding: 1px;
}

.react-pdf__Page__textContent .highlight.begin {
  border-radius: 4px 0px 0px 4px;
}

.react-pdf__Page__textContent .highlight.end {
  border-radius: 0px 4px 4px 0px;
}

.react-pdf__Page__textContent .highlight.middle {
  border-radius: 0px;
}

.react-pdf__Page__textContent .highlight.selected {
  background-color: rgba(0, 100, 0, 1);
}

.react-pdf__Page__textContent ::-moz-selection {
  background: rgba(0, 0, 255, 1);
}

.react-pdf__Page__textContent ::selection {
  background: rgba(0, 0, 255, 1);
}

.react-pdf__Page__textContent .endOfContent {
  display: block;
  position: absolute;
  left: 0px;
  top: 100%;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  cursor: default;
  user-select: none;
}

.react-pdf__Page__textContent .endOfContent.active {
  top: 0px;
}

.popupAnnotation {
  display: none;
}
