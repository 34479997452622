@import 'colors';

$border-radius-common: 5px;
$border-radius-round: 20px;
$box-shadow-common: 0 2px 17px 0 rgba($black-900, .15);

$border-grey-v2: $gray-300;

$border-separator: solid 1px $gray-200;
$border-separator-on-sidebar: 1px solid rgba($white, .15);

$side-bar-width: 240px;
$side-bar-element-height: 60px;
$content-padding-horizontal: 40px;
$page-header-height: 60px;
$page-header-title-height: 60px;
$page-sub-header-height: 60px;
$sticky-footer-height: 60px;

$hover-transition-time: .15s;
$table-row-color-transition: background-color $hover-transition-time linear;

$z-index-of-side-bar-pop-up: 20000;
$z-index-mobile-warning-message: 10;

$fw-regular: 400;
$fw-semibold: 600;
$fw-bold: 700;
