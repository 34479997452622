.VideoPlayer {
  video,
  iframe {
    max-width: 100%;
  }

  iframe {
    height: 400px;
    width: 700px;
    border: none;
  }
}
