.ToolButton {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in;
  background: transparent;
  border: 0;
  outline: 0;

  &--selected {
    background-color: rgba($color: #000, $alpha: 0.2);
  }

  &:hover {
    background-color: rgba($color: #000, $alpha: 0.2);
    cursor: pointer;
  }

  &:active {
    opacity: 0.5;
  }
}