$baserem: 20;

$desktop-min-width: 770;
$iphone4-max-height: 480;
$iphone5-max-width: 320;
$iphone4-max-width: 320;

@function rem($px) {
  @return calc($px/$baserem) + rem;
}

//see usage in README.md
@mixin media($width, $height:null, $orientation: false) {
  $query: "";
  $min-width: false;
  $max-width: false;
  $min-height: false;
  $max-height: false;
  @if (type-of($width) == list) {
    $min-width: nth($width, 1);
    $max-width: nth($width, 2);
  } @else if (type-of($width) == number){
    $max-width: $width;
  }
  @if (type-of($height) == list) {
    $min-height: nth($height, 1);
    $max-height: nth($height, 2);
  } @else if (type-of($height) == number){
    $max-height: $height;
  }
  @if (type-of($min-width) == number) {
    $query: $query+"and (min-device-width: #{$min-width}px)";
  }
  @if (type-of($max-width) == number) {
    $query: $query+"and (max-device-width: #{$max-width}px)";
  }
  @if (type-of($min-height) == number) {
    $query: $query+"and (min-device-height: #{$min-height}px)";
  }
  @if (type-of($max-height) == number) {
    $query: $query+"and (max-device-height: #{$max-height}px)";
  }
  @if $orientation {
    $query: $query+"and (orientation: #{$orientation})"
  }
  @media screen #{$query} {
    @content;
  }
}

@mixin media-desktop {
  @include media(($desktop-min-width, null)) {
    @content;
  }
}

@mixin media-mobile($orientation: null) {
  @include media(($iphone5-max-width, $desktop-min-width - 1), null, $orientation) {
    @content;
  }
}
