@import '../../colors';
@import '../../variables';

.Typography {
  &--bold {
    font-weight: $fw-bold;
  }

  &--semibold {
    font-weight: $fw-semibold;
  }

  &--centered {
    text-align: center;
  }

  &__block-title {
    color: $black-900;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  &__block-title--disabled {
    color: $gray-900;
  }

  &__section-title {
    color: $black-900;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
  }

  &__big-title {
    color: $black-900;
    font-size: 22px;
    font-weight: bold;
    line-height: 30px;
  }

  &__section-highlight {
    color: $black-900;
    font-size: 24px;
    font-weight: 600;
    line-height: 20px;
  }

  &__caption {
    color: $gray-900;
    font-size: 12px;
    line-height: 16px;
  }

  &__regular {
    color: $black-900;
    font-size: 14px;
    line-height: 19px;
  }

  &__regular--small {
    color: $gray-900;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }

  &__control-text {
    color: $gray-900;
    font-size: 14px;
    line-height: 19px;
  }

  &__content-text {
    color: $gray-900;
    font-size: 16px;
    line-height: 23px;
  }

  &__caption-regular {
    font-size: 12px;
    line-height: 18px;
  }
}
