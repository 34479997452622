$blue-900: #225696;
$blue-800: #0071c9;
$blue-700: #0c83df;
$blue-500: #8cbfe7;
$blue-200: #c7e1f4;
$blue-100: #f4f9fe;

$green-900: #4ab672;
$green-700: #76d599;
$green-100: #e4f7eb;

$yellow-900: #f5bd20;
$yellow-700: #f3cc81;
$yellow-100: #fefae8;

$red-900: #df2f2f;
$red-600: #ff6c68;
$red-100: #fceeed;

$violet-500: #a7b0d0;

$pink-500: #e7b2b2;

$turquoise-500: #a1d1d0;

$black-900: #192336;
$black-800: #283750;

$gray-900: #8993a4;
$gray-600: #c1cbdb;
$gray-300: #d6dce5;
$gray-200: #edeef2;
$gray-100: #f5f7f9;

$white: #fff;
$black: #000;

$text-success: $green-700;
$text-warning: $yellow-900;
$text-error: $red-600;
$text-info: $gray-900;
