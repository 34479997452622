.VideoMarkers {
  margin-top: 25px;
  padding: 20px 15px;

  display: flex;
  flex-flow: column;

  letter-spacing: -0.41px;

  .marker-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-bottom: 5px;

    .time {
      color: #0071c9;
      padding: 5px;
      cursor: pointer;
    }

    .title {
      margin-left: 15px;
    }
  }
}
