@import '../../colors';
@import '../../variables';

.Kb-Dropdown {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 2px 0 6px;
  border-radius: $border-radius-common;

  &:hover,
  &--open {
    background: $gray-200;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__label {
    margin-left: 5px;
    cursor: pointer;
    user-select: none;
  }

  &__chevron {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3px;
  }

  &__options {
    width: 140px;
    padding: 12px 0;
    border-radius: 3px;
    background-color: $white;
    box-shadow: 0 2px 17px rgba(0, 0, 0, .195143);
    list-style: none;
  }

  &__option {
    padding: 8px 15px;

    &__contents {
      &__row {
        display: flex;
        flex-direction: row;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      height: 26px;
      margin: 0;
      padding: 10px 0;
      list-style: none;
    }

    &__upgrade {
      margin-left: auto;

      &__label {
        margin-top: 2px;
        padding: 2px 8px;
        border-radius: $border-radius-round;
        background-color: $green-100;
        color: $green-900;
        font-size: 12px;
        font-weight: $fw-semibold;
      }
    }

    &--enabled {
      cursor: pointer;

      &:hover {
        background-color: $blue-100;
      }
    }

    &--selected {
      background: $gray-100;
    }

    &--not-selected {
      padding-left: 40px;
    }

    &--disabled {
      cursor: pointer;

      & div.Kb-Dropdown__option__item,
      & div.Kb-Dropdown__option-description {
        opacity: .4;
      }
    }
  }

  &__option-label {
    margin-left: 10px;
    color: $black;
    font-family: 'Verdana';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    cursor: inherit;
  }

  &__option-description {
    padding-left: 30px;
    color: $gray-900;
    font-family: 'Open Sans', serif;
    font-size: 12px;
    cursor: inherit;
  }
}
