.PdfPreview {
  $previewBackgroundColor: #fff;

  background-color: $previewBackgroundColor;

  &__Page {
    margin: 0;
    line-height: 1;

    .react-pdf__Page__canvas {
      margin-left: auto;
      margin-right: auto;
    }

    &-inner-container {
      margin: 12px auto;
    }
  }

  .highlight {
    color: transparent;
    opacity: 0;
    background-color: #ff0;
  }

  &-page {
    background-color: $previewBackgroundColor;
  }

  &--draggable {
    cursor: move;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  &--highlight-query {
    .highlight {
      opacity: 1;
    }
  }

  &.react-pdf__Document {
    .Loader {
      background-color: $previewBackgroundColor;
    }
  }

  .react-pdf__message {
    color: #fff;
    text-align: center;
    margin-top: 24px;
  }
}
