@import '~pdfjs-dist/web/pdf_viewer.css';
@import '../../utils';

body {
  font-family: Verdana, sans-serif;
  margin: 0 !important;
}

.ContentPreview {
  display: flex;
  transition: all 0.2s;

  @include media-desktop {
    box-shadow: rgba(87, 110, 130, 0.37) 0 6px 9px 0;
    height: 100%;
  }

  &.expanded {
    height: 100%;
  }

  .MainContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .Header {
      font-family: Verdana, sans-serif;
      padding: 14px 30px 18px;
      min-height: 50px;
      height: auto;
      background: #f7f8f8;
      border-bottom: 1px solid #DFE3E6;

      &__info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 4px;

        font-size: 10px;
        line-height: 12px;
        text-align: right;
        letter-spacing: -0.34375px;
        color: #5E7181;
      }

      &__langugaes {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      &__langugaes--secleted {
        margin-left: 5px;
        margin-right: 3px;
      }

      .privateIcon {
        margin-left: 5px;
        vertical-align: bottom;
      }

      &__title {
        font-size: 20px;
        line-height: 1.3em;
        letter-spacing: -0.7px;
        color: #000000;
        word-break: break-word;
      }

      @include media-mobile {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }

    &.PDF .Header {
      @include media-mobile {
        margin-top: 50px;
      }
    }

    .Content {
      @include media-desktop {
        overflow: auto;
      }

      background: white;
      flex: 1; // TODO: unify this with the browser plugin by moving sizing to external container

      .LoadError {
        padding: 0 30px;

        &__retry-btn {
          background-color: transparent;
          border: 0;
          outline: 0;
          font-size: inherit;
          font-family: inherit;
          font-weight: inherit;
          color: #00e;
          cursor: pointer;
        }
      }

      .LoaderWrapper {
        width: 100%;
        text-align: center;
        padding-top: 15%;
      }

      em.highlight {
        border-bottom: 2px solid red;
        font-style: inherit;
      }

      .content-container {
        display: flex;
        justify-content: center;
        align-content: normal;
        width: 100%;
        height: 100%;
        font-size: rem(20);
        margin: 0 auto;

        &.PDF {
          width: 100%;
        }

        @include media-mobile {
          min-height: auto;
          height: calc(100vh - 130px);
        }

        // styles for HTML received in response
        .html-content {
          margin: 8px;
          width: 100%;
          word-break: break-word;

          iframe {
            border: none;
          }

          @include media-mobile {
            padding: 0 20px;
          }

          img {
            max-width: 100%;
          }

          h1.title {
            display: none;
          }

          .fig {
            position: relative;
            width: fit-content;
          }

          .hotspot-link {
            display: block;
            width: 15px;
            height: 15px;
            position: absolute;
            border-radius: 50%;
            background-color: #fff;
            border: 2px solid #4a8fe1;
            box-shadow: 0 0 2px 5px rgba(67, 155, 230, .32);
            color: #000;
            line-height: 1.4;
            text-align: center;
            text-decoration: none;
            outline:none;
            cursor: default;

            &:before {
              content: '';
              position: absolute;
              width: inherit;
              height: inherit;
              top: -2px;
              left: -2px;
            }
          }
        }
      }
    }
  }

  .ToolsPanel {
    width: 51px;
    flex-shrink: 0;
    background-color: #0071c9;
    align-items: center;
    display: flex;
    flex-direction: column;

    .icon {
      width: 16px;
      cursor: pointer;
      padding: 5px 10px;
      margin: 15px 0;

      &:active {
        opacity: 0.5;
      }
    }

    .TopActions, .BottomActions {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      align-items: center;
    }

    .BottomActions {
      justify-content: flex-end;
    }

    @include media-mobile {
      position: fixed;
      top: 0;
      flex-direction: row;
      width: 100%;
      padding: 0 10px;

      .icon {
        padding: 10px 25px;
        margin: 0;
      }

      .BottomActions {
        flex-direction: row!important;
        justify-content: left;
      }

      .TopActions {
        display: none;
      }
    }
  }

  .PdfPreview {
    width: 100%;
  }

  &:not(.for-iframe) {
    .MainContainer.Article .Content .content-container {
      height: auto;
    }

    .MainContainer .Content .content-container {
      &.Article,
      &.Video {
        width: 100%;
        max-width: 813px;
      }
    }
  }

  &.for-iframe {
    position: relative;

    .ToolsPanel {
      display: none;
    }

    .MainContainer {

      &.Video {
        .Content {
          margin-top: 30px;
        }
      }

      &.Article {
        width: 100vw;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
      }
    }

    .Content {
      .Video {
        padding: 0 30px;
      }

      .VideoMarkers {
        max-height: 200px;
        overflow: auto;
      }

      .html-content {
        padding: 0 30px;
        overflow: auto;
      }
    }

    .PdfPreview {
      background: #fff;

      &.react-pdf__Document .Loader {
        background-color: #fff;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .Loader {
      margin-top: 26px;
    }

    .PdfPreview__Page .Loader {
      margin-top: 0;
    }

  }
}
