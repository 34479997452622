.matches {
  display: inline-flex;

  &__text {
    margin-right: 8px;
  }

  &__icon {
    display: flex;
    padding: 3px 5px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}
